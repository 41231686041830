import React, { Component } from 'react';

export default class Bar extends Component{

    render() {
        return (
            <div className='bar' style={{flexDirection:this.props.position==="right"?"row":"row-reverse"}}>
                <div style={{backgroundColor:this.props.color}}></div>
                <h1 style={{color:this.props.color}}>{this.props.number}</h1>
            </div>
        );
    }
}