import React, { Component } from 'react';

export default class skills extends Component{

    render() {
        return (
             <section id="skills">

                <div className='sk-head'>
                    <h4 className='m-0'>With a proactive problem-solving approach and a commitment to innovation, I bring a dynamic skill set to contribute effectively to organizational success.</h4>
                </div>
                <div className='sk-body'>
                    <div className="sk-txt">
                        <p style={{position:'relative',bottom:-30}}>Nginx</p>
                        <p className="t36" style={{fontFamily:'Mynerve'}}>Agile/Scrum</p>
                        <p className="t20 bolder">ReactJS</p>
                    </div>
                    <div className="sk-txt" style={{marginLeft:-80}}>
                        <p  style={{position:'relative',bottom:-20,fontFamily:'IBM Plex Mono'}}>C++</p>
                        <div style={{width:60}}></div>
                        <p className="t40 bold" style={{fontFamily:'Rubik Doodle Shadow'}}>SQL</p>
                        <div style={{width:40}}></div>
                        <p className="t20">Laravel</p>
                        <div style={{width:20}}></div>
                        <p>Linux Administration</p>
                    </div>
                    <div className="sk-txt">
                        <p >C</p>
                        <p className="t20" style={{fontFamily:'Titillium Web'}}>Qt</p>
                        <p className="t70" style={{marginTop:-20}}>PHP</p>
                        <div className='' style={{marginTop: -20}}>
                            <p className='m-0 p-0 t36'>Django</p>
                            <div className='row'  style={{marginTop:-25}}>
                                <p style={{paddingLeft:46,marginRight:40,fontFamily: 'IBM Plex Mono, monospace'}}>HTML</p>
                                <p>JQuery</p>
                            </div>
                        </div>
                        <p style={{fontFamily:'Aboreto',fontWeight:600}}>WordPress</p>
                    </div>
                    <div className="sk-txt"  style={{marginTop:-20}}>
                        <p className="t20" style={{fontFamily:'Rubik Marker Hatch'}}>UML</p>
                        <p className="t24">VanillaJS</p>
                        <p className="t24 bold" style={{fontFamily:'Edu VIC WA NT Beginner',fontStyle:'italic',position:'relative',top:-10}}>CSS</p>
                        <p className="t10" style={{paddingLeft:30}}>Android</p>
                        <p style={{position:'relative',top:-20,right:-30}}>Apache</p>
                    </div>
                    <div className="sk-txt">
                        <p className="t12">Mobile APP</p>
                        <p className="t26 bold">React Native</p>
                        <p >Git</p>
                        <p className="t80" style={{marginTop:-30,fontFamily:'Instrument Serif'}}>JAVA</p>
                    </div>
                    <div className="sk-txt" style={{marginTop:-30}}>
                        <p className="t16">UI/UX</p>
                        <p className="t14">Figma</p>
                        <p className="t56" style={{fontFamily:'Outfit'}}>AWS</p>
                        <p className="t10 bold" style={{marginTop:6, marginLeft:90}}>Spring Boot</p>
                    </div>
                </div>
             </section>
        );
    }
}