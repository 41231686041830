import React, { Component } from 'react';
import Bar from '../Components/Bar';
import emailjs from '@emailjs/browser';

export default class Contact extends Component{

    constructor(props){
        super(props);
        this.state = {
            fullname : '',
            subject : '',
            email : '',
            message : '',
            sending : false
        }
        this.form = React.createRef();
    }

    send = (e) => {
        e.preventDefault();
        console.warn(this.state);
        this.setState({sending:true});
        emailjs
            .sendForm('service_vrsx3nj', 'template_oy9mbam', this.form.current, {
                publicKey: 'iBLRlyHbXjktNFXAC',
            })
            .then(() => {
                this.setState({fullname : '',
                subject : '',
                email : '',
                message : '',
                sending : false});
                alert('Thanks Dear Client ! I will Contact you Soon !! 🙏🏾')
            },
            (error) => {
                alert('FailED ! 🙏🏾 Please Send a direct message to (viostanojguerrier@gmail.com)')
                console.log('FAILED...', error.text);
                this.setState({sending:false});
            },
        );
    }

    render() {
        return (
            <section id='contact' className='content bg-primary'>

                <div className='c-text'>

                                        
                <div style={{display:'flex',marginTop:20,marginBottom:20,width:'100%'}}>
                        <Bar number="04." color={"white"} position="left"/>
                    </div>
                    <h1>Let's talk <br className='ctxtbr' />about your <br /> <span className='wheat'>Project</span>.</h1>

                    <div className='footer'>
                        <h5>@ 2024 - Vio StanJ</h5>
                        <small>Credits : <a href="https://icons8.com/" target='__blank'>Icons8.com</a> | <a href="https://alvarotrigo.com/fullPage/">FullPage.JS</a></small>
                    </div>
                </div>

                <div>
                    <form ref={this.form} onSubmit={this.send} id='contactform'>
                        <div className='forminput'>
                            <label htmlFor="">Fullname</label>
                            <input type="text" name='fullname' value={this.state.fullname} onChange={(e)=>this.setState({fullname:e.target.value})}
                                required=""/>
                        </div>

                        <div className='forminput'>
                            <label htmlFor="">E-mail</label>
                            <input type="email" name='email' value={this.state.email} onChange={(e)=>this.setState({email:e.target.value})}
                                required=""/>
                        </div>

                        <div className='forminput'>
                            <label htmlFor="">Subject</label>
                            <input type="text" name='subject' value={this.state.subject} onChange={(e)=>this.setState({subject:e.target.value})}
                                required=""/>
                        </div>

                        <div className='forminput'>
                            <label htmlFor="">Message</label>
                            <textarea type="text" name='message' rows={4} value={this.state.message} onChange={(e)=>this.setState({message:e.target.value})}
                                required=""/>
                        </div>

                        <button type="submit">Send</button>
                        {
                            this.state.sending?
                                <img src="./assets/img/loading.gif" alt="Loading..." id='loading'/>
                            :null
                        }
                    </form>
                </div>
                <div className='footer2 footer'>
                    <h5 className='m-0'>@ 2024 - Vio StanJ</h5>
                    <small>Credits : <a href="https://icons8.com/" target='__blank'>Icons8.com</a> | <a href="https://alvarotrigo.com/fullPage/">FullPage.JS</a></small>
                </div>

                {/* <div id="loading">
                </div> */}
            </section>
        );
    }
}