import React from 'react'
import { Link, Element } from 'react-scroll';

export default class header extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          scrolling: false,
          wheigth : 0,
          color : 'primary',
          anchor : 'bg-primary',
          git : './assets/img/github.png',
          mail : './assets/img/email.png'
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }


    render() {
        const op = this.props.index>=0?"":"opac";
        return (
             <header id='header' className={this.state.color+" section"}>

                {/* <div id="media-control" style={{width: "100%",height: 12,position: "absolute",top: 0}}>
                </div> */}

                <div id='anchor'>
                    <div className={this.props.anchor}></div>
                    <div className='bg-wheat'></div>
                </div>


                <div id='menu-list' className={op}>
                    <ul>
                        <li><Link className={this.props.color+' pointer uppercase'} to="presentation" smooth={true} duration={500}>Home</Link></li>
                        <li><Link className={this.props.color+' pointer uppercase'} to="about" smooth={true} duration={500}>About</Link></li>
                        {/* <li><Link className={this.props.color+' pointer uppercase'} to="skills" smooth={true} duration={500}>Skills</Link></li> */}
                        <li><Link className={this.props.color+' pointer uppercase'} to="projects" smooth={true} duration={500}>Projects</Link></li>
                        <li><Link className={this.props.color+' pointer uppercase'} to="contact" smooth={true} duration={500}>Contact</Link></li>
                    </ul>
                </div>

                <div id='menu-social'>
                    <a href="https://github.com/VioStanJ" target='_blank' rel="opener">
                        <img src={this.props.git} alt="LinkedIn" />
                    </a>
                    <a href="https://www.linkedin.com/in/vio-stanoj-guerrier/" target='_blank' rel="opener">
                        <img src="./assets/img/linkedin.png" alt="LinkedIn" />
                    </a>
                    <a href="mailto:viostanojguerrier@gmail.com">
                        <img src={this.props.mail} alt="Email" />
                    </a>
                </div>
                
             </header>
        );
    }
}