import React from 'react'
import Header from './../Components/Header';
import Presentation from '../Content/Presentation';
import About from '../Content/About';
import Project from '../Content/Project';
import ReactFullpage from '@fullpage/react-fullpage'
import Contact from '../Content/Contact';

export default class Home extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
        //   licenseKey: 'YOUR_KEY_HERE',
          scrollingSpeed: 1000,
          index : 0,
          color : 'primary',
          anchor : 'bg-primary',
          git : './assets/iwmg/github.png',
          mail : './assets/img/email.png'
        };
      }

      afterLoad = (origin, destination, direction) => {
            this.setState({index:destination.index});
            this.updateMenu(destination.index);
      };
    
      onLeave = (origin, destination, direction) => {
        // console.log('On leave:', { origin, destination, direction });
        // Perform actions before leaving the section/slide
        this.setState({index:-1});
      };


    updateMenu = (index) => {
        if(index === 0){
            this.setState({color:'primary',anchor:'bg-primary',git:'./assets/img/github.png',mail:'./assets/img/email.png'});
        }
        
        if(index === 1){
            this.setState({color:'wheat',anchor:'bg-white',git:'./assets/img/wgit.png',mail:'./assets/img/wemail.png'});
        }
        
        // if(index === 2){
        //     this.setState({color:'primary',anchor:'bg-primary',git:'./assets/img/github.png',mail:'./assets/img/email.png'});
        // }

        if(index === 2){
            this.setState({color:'primary',anchor:'bg-primary',git:'./assets/img/github.png',mail:'./assets/img/email.png'});
        }

        if(index === 3){
            this.setState({color:'white',anchor:'bg-white',git:'./assets/img/wgit.png',mail:'./assets/img/wemail.png'});
        }
        
    }
      
    render() {
        return (
            <>
                {/* <video autoPlay muted loop id="myVideo">
                    <source src="https://personal-stanj-web-content.s3.amazonaws.com/background.mp4" type="video/mp4"/>
                </video> */}
                <Header 
                    index={this.state.index}
                    anchor={this.state.anchor}
                    color={this.state.color}
                    git={this.state.git}
                    mail={this.state.mail}/>
                <ReactFullpage
                    scrollingSpeed={1000}
                    afterLoad={this.afterLoad}
                    onLeave={this.onLeave}
                    render={({  }) => {
                        return (
                        <ReactFullpage.Wrapper>
                            <div className="section">
                                <Presentation />
                            </div>

                            <div className="section">
                                <About/>
                            </div>

                            <div className="section">
                                <Project/>
                            </div>

                            <div className="section">
                                <Contact/>
                            </div>

                        </ReactFullpage.Wrapper>
                        );
                    }}
                />
            </>
        );
    }
}