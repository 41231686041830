import React, { Component } from 'react';
import Bar from '../Components/Bar';

export default class Presentation extends Component{

    render() {
        return (
             <section id="presentation" className='content'>
                
                <img src="./assets/img/vio.png" alt="Guerrier Vio Stanoj" />
                
                <div id='presentation-text'>
                    <Bar number=".01" color={"#100c3e"} position="right"/>

                    {/* <img src="./../assets/img/soft_dev.svg" alt="Software Engineer & Dev" /> */}
                    <h1 className='primary soft m-0'>Software Engineer <br /><span>& Developer</span></h1>
                    <small>From Haïti</small>
                    <div className='summary'>
                        <h1 className='primary'>Hi ! I'm, VIO STANOJ</h1>
                        <h1 className='primary'>Super motivated and creative software developer with teamwork experience and a keen
                        sense of innovation. I'm a proactive problem solver and strong team player, ready to drive innovation and
                        achieve organizational goals.</h1>
                    </div>
                    
                    <div className='p-buts'>
                        <button>Need a Dev ?</button>
                        <button onClick={()=>window.location.href="https://personal-stanj-web-content.s3.amazonaws.com/Vio-Stanoj-Guerrier-Full-Stack-Resume.pdf"}>Download My Resume</button>
                    </div>
                    
                </div>
             </section>
        );
    }
}