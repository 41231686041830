import React, { Component } from 'react';
import list_projects from './../projects.json';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCards } from 'swiper/modules';
import { Pagination, Navigation } from 'swiper/modules';
import ProjectCard from '../Components/ProjectCard';
import Bar from '../Components/Bar';

export default class Project extends Component{

    constructor(props){
        super(props);
        this.state = {
            projects : list_projects
        }
    }

    handleDragStart = (e) => {
        e.preventDefault();
    }

    render() {
        
        const items = this.state.projects.map((el,ind)=>{
            return <SwiperSlide>
            <ProjectCard project={el} index={ind}/>
        </SwiperSlide>
        });

        return (
             <section id='projects' className='content'>
                
                <div style={{width:"100%",display:'flex',flexDirection:'column',alignItems:'flex-end'}}>
                    <Bar number=".03" color={"#100c3e"} position="right"/>
                </div>

                <br />
                <Swiper
                    effect={'cards'}
                    grabCursor={true}
                    modules={[EffectCards,Navigation,Pagination]}
                    className="mySwiper"
                    navigation={true}>
                    {items}
                </Swiper>

             </section>
        );
    }
}