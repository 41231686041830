import React, { Component } from 'react';
import Skills from './Skills';
import Bar from '../Components/Bar';

export default class About extends Component{

    constructor(props){
        super(props);
        this.state = {
            index : 1
        }
    }
    render() {
        return (
            <section id='about' className='content'>

                <div className='bar-about'>
                    <Bar number="02." color={"white"} position="left"/>
                </div>

                <div>
                    <div id='labels'>
        
                        <div className='pointer' onClick={()=>this.setState({index:1})}>
                            <div className={`clipath ${this.state.index===1?"active-clip":""}`}></div>
                            <h4>Summary</h4>
                        </div>
                        <div className='pointer' onClick={()=>this.setState({index:2})}>
                            <div className={`clipath ${this.state.index===2?"active-clip":""}`}></div>
                            <h4>Education</h4>
                        </div>
                        <div className='pointer' onClick={()=>this.setState({index:3})}>
                            <div className={`clipath ${this.state.index===3?"active-clip":""}`}></div>
                            <h4>Skills</h4>
                        </div>
                    </div>

                    <div id='about-contant'>
                        <div>
                            {
                                this.state.index===1?
                                <h4 className='ab-text'>
                                    Hello, I'm <span className="wheat bold">Vio Stanoj Guerrier</span>, a dedicated technologist <span className="wheat bold">passionate</span> about crafting innovative <span className="wheat bold">solutions</span>. In the realm of back-end development, I wield expertise in <span className="wheat bold">Laravel</span>, <span className="wheat bold">Django</span>, <span className="wheat bold">Node.js</span>, <span className="wheat bold">Java</span>, and <span className="wheat bold">WordPress</span>. On the front-end, I create seamless user experiences with <span className="wheat bold">ReactJS</span>, <span className="wheat bold">JavaScript</span>, <span className="wheat bold">Blade</span>, <span className="wheat bold">HTML/CSS</span>, and employ <span className="wheat bold">Figma for UI/UX</span> design. Mobile app development is my forte, encompassing <span className="wheat bold">React Native</span> and native <span className="wheat bold">Android</span>. I navigate the intricacies of <span className="wheat bold">APIs</span>, <span className="wheat bold">Web Services</span>, <span className="wheat bold">Agile/Scrum</span> methodologies, and <span className="wheat bold">UML-based</span> database design.
                                    <br /><br className='br'/>
                                    Beyond coding, I'm proficient in languages - French and English - and find joy in the disciplines of <span className="wheat bold">Kyokushin</span> Karate and <span className="wheat bold">Piano</span> melodies. <span className="wheat bold">Join Me</span> on this journey where <span className="wheat bold">technology meets creativity</span>, and let's <span className="wheat bold">build</span> something extraordinary <span className="wheat bold">together</span> !
                                </h4>:null
                            }

                            {
                                this.state.index===2?
                                <>
                                
                                    <div className='education'>

                                        <h2 className='white'>Bachelor's degree in computer science</h2>
                                        <small className='bold'>Nov 2015 - Oct 2019</small>
                                        <h3>UNIKA (Université Kiskeya Amerique)</h3>
                                        <small><a href="https://inuka.edu.ht/" target='__blank'><img src="./../assets/img/link.png" alt="INUKA"/></a></small>
                                    </div>
                                    
                                    <div className="bar"></div>
                                </>
                                :null
                            }

                            {
                                this.state.index===3?
                                <Skills/>
                                :null
                            }
                        </div>
                    </div>
                </div>
                
            </section>
        );
    }
}