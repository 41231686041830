import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

export default class ProjectCard extends Component{

    render() {
        const project = this.props.project;

        return (
            <article style={{backgroundColor:project.background}} id='pcard'>
                <div className='procard'>
                    <div>
                        <div className='card-tech'>
                            {
                                project.tech.map((el)=>{
                                    return <span className='prevent-select'>{el}</span>
                                })
                            }
                            {
                            project.git?
                                <a href={project.gitlink} target='__blank'>
                                    <img src="./assets/img/wgit.png" alt={`Source Code - ${project.meta}`} target="__blank" className='card-git prevent-select'/>
                                </a>
                            :null
                        } 
                        </div>
                        
                    </div>
                    <div>
                        <div className='div-title'>
                            {
                                String(project.name).length>0?
                                <h1 className="m-0 prevent-select card-title">{project.name}</h1>:null
                            }

                            {
                                String(project.logo).length>0?
                                    <img src={project.logo} alt={`Logo-${project.meta}`} className='card-logo prevent-select'/>
                                :null
                            }
                        </div>
                        <h6 className="prevent-select card-desc">{project.description}</h6>
                    </div>
                    <div className='card-footer'>
                        <div>
                            {
                                project.links.map((el)=>{
                                    return <a href={el.link}  target='__blank' style={{color:el.color}} id='card-link' className="prevent-select">
                                        {el.title} <img src={el.icon} alt={`link-${el.title}`} srcset="" /></a>
                                })
                            }
                        </div>
                        <div>
                            <a href={project.link} target='__blank' className='prevent-select'>
                                <img src="./assets/img/goto.png" alt={`GoTo-${project.meta}`} style={{height:30}}/>
                            </a>
                        </div>
                    </div>
                </div>
            </article>
        );
    }
}